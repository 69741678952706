*{
  margin: 0;
  padding: 0;
}
p{
  margin: 0 !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
	display: flex;
}
.flex_just_betw{
	display: flex;
	justify-content: space-between;
}
.flex_just_around{
	display: flex;
	justify-content: space-around;
}
.flex_just_cen{
	display: flex;
	justify-content: center;
}
.flex_just_end{
	display: flex;
	justify-content: flex-end;
}
.flex_wrap{
	flex-wrap: wrap
}
.flex_cen{
	align-items: center;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.m10 {
  margin: 10px;
}
.m20 {
  margin: 20px;
}
.m30 {
  margin: 30px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.tx_c{
  text-align: center;
}
.tx_l{
  text-align: left;
}
.bgf{
  background-color: #fff !important;
}
.bor {
  border: 1px solid #ddd;
}
.bor_t {
  border-top: 1px solid #ddd;
}
.bor_l {
  border-left: 1px solid #ddd;
}
.bor_r {
  border-right: 1px solid #ddd;
}
.bor_b {
  border-bottom: 1px solid #ddd;
}
.shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 26, 79, 0.14);
}


/*滚动条样式*/
.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 2px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 0;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.main_container{
  width: 100%;
  height: calc(100vh - 150px);
  padding: 20px 20px 0px 20px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.table_list_box{
  width: 100%;
}
.table_list_box.syslog{
  height: calc(100% - 60px);
  
}
.list_pagitation_box{
  width: 100%;
  height: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.list_pagitations{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pagetationSizer{
  width: 100px;
  height: 32px;
  border: 1px solid #d3d2d2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  border-radius:5px ;
}
.pagetationSizer:hover{
  border-color: rgba(94, 121, 235, 1);
  color: rgba(94, 121, 235, 1);
}
.formItemStyle {
  /* padding-top: 30px !important; */
  margin-right: 30px !important;
  margin-bottom: 20px !important;
  position: relative;
  /* margin-left: 30px !important; */
}
.formItemStyle .ant-form-item{
  margin-right: 0px !important;
}
.formItemStyle .ant-picker{
  padding:4px 9px 4px !important;
}
.searchArea{
  width: 100%;
  display: table;
}
.ant-form-item-control{
  position: relative;
}
.ant-form-item-explain{
  position: absolute;
  left: 0;
  font-size: 13px !important;
  bottom: -25px;
}
.loadingStyle{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  z-index: 500;
}
.popupMain{
  position: relative;
}

.congif_root_tabs{
  width: 1150px;
  height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
}
.activedTitle{
  color: red;
}
.phone_watch_style{
  color: blue;
  cursor: pointer;
}
.phone_watch_style:hover{
  text-decoration: underline;
}
.loginOutBtn{
  cursor: pointer;
  color: #000;
}
.loginOutBtn:hover{
  color: blue;
}